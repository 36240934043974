import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import PageBanner from "../containers/global/page-banner";
import BemcsPageContainer from "../containers/bemcs-page-container";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";

const BemcsPage = () => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Aranaya – BEMCS" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title="BEMCS"
                        excerpt=""
                        image="./images/bemcs/bemcs.png"
                    />
                    <BemcsPageContainer />
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default BemcsPage;
