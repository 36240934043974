import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NavScrollTop from "./components/nav-scroll-top";
import HomePage from "./pages/index";
import AboutPage from "./pages/about";
import ServicePage from "./pages/service";
import ServiceDetails from "./templates/service-details";
import BemcsPage from "./pages/bemcs";
import UafPage from "./pages/uaf";
import ImpartialityPage from "./pages/impartiality";
import ContractPage from "./pages/policy-contract";
import ContactPage from "./pages/contact";
import CertificatePage from "./pages/certificate";
import "swiper/components/navigation/navigation.scss";
import "swiper/swiper.scss";
import "./assets/css/vendor/metropolis.css";
import "./assets/css/vendor/icofont.min.css";
import "./assets/css/vendor/font-awesome.css";
import "./assets/css/vendor/material-design-iconic.min.css";
import "./assets/css/plugins/animate.min.css";
import "./assets/scss/style.scss";
import ProcessPage from "./pages/process";
import ComplaintPage from "./pages/complaint";
import AppealPage from "./pages/appeal";
import PolicyPage from "./pages/policy";
import PolicyApplicationPage from "./pages/policy-application";
import QualitipolicyPage from "./pages/quality";
import ApplyPage from "./pages/apply";

const App = () => {
    return (
        <Router>
            <NavScrollTop>
                <Switch>
                    <Route
                        path={`${process.env.PUBLIC_URL + "/"}`}
                        exact
                        component={HomePage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/about"}`}
                        component={AboutPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/service"}`}
                        component={ServicePage}
                    />
                    <Route
                        path={`${
                            process.env.PUBLIC_URL + "/service-details/:path"
                        }`}
                        component={ServiceDetails}
                    />
                    <Route
                        path={`${
                            process.env.PUBLIC_URL + "/accreditation_bemcs"
                        }`}
                        component={BemcsPage}
                    />
                    <Route
                        path={`${
                            process.env.PUBLIC_URL + "/accreditation_uaf"
                        }`}
                        component={UafPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/contact"}`}
                        component={ContactPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/certificate"}`}
                        component={CertificatePage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/policy"}`}
                        component={PolicyPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/quality"}`}
                        component={QualitipolicyPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/impartiality"}`}
                        component={ImpartialityPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/contract"}`}
                        component={ContractPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/application"}`}
                        component={PolicyApplicationPage}
                    />
                    <Route
                        path={`${
                            process.env.PUBLIC_URL + "/certificate_process"
                        }`}
                        component={ProcessPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/appeal"}`}
                        component={AppealPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/complaint"}`}
                        component={ComplaintPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/apply"}`}
                        component={ApplyPage}
                    />
                </Switch>
            </NavScrollTop>
        </Router>
    );
};

export default App;
