import React from "react";

const ComplaintContainer = () => {
    return (
        <section className="contact-section section-py">
            <div className="container">
                <div className="row mb-n7">
                    <p>
                        We believe in managing our business affairs in strictly
                        professional manner. Interested parties are requested to
                        submit their complaints against behavior of our
                        personnel or any act of commission or omission committed
                        by our personnel in course of their professional work
                        and interaction. The complaint can be lodged in writing
                        and can be submitted by courier or by Email. All
                        complaints are registered and the complaint is
                        acknowledged within 3 working days. The CEO directly
                        looks into every complaint and the complainant is made
                        aware of the steps taken within 10 working days.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default ComplaintContainer;
