import React from "react";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";

const ProcessPage = () => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Aranaya – Certification Process" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title="Certification Process"
                        image="./images/contact/certificate.png"
                    />
                    <div className="mt-3 text-center align-items-center">
                        <img
                            src="./images/usefullink/process.png"
                            alt="Certification Process"
                        />
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default ProcessPage;
