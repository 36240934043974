import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { slugify } from "../../utils";

const BlogGrid = ({ data, classOption }) => {
    return (
        <div className="blog-card">
            <div className={`thumb bg-light text-center ${classOption}`}>
                <Link to={process.env.PUBLIC_URL + `/blog-details/${data.id}`}>
                    <img
                        src={`${process.env.PUBLIC_URL}/${data.media.gridImage}`}
                        alt="img"
                    />
                </Link>
            </div>
            <div className="blog-content">
                {/* <p>
                    We at AMSPL, are committed to provide management system
                    conformity assessment & certification services to our
                    clients in a manner that would elevate the prestige of AMSPL
                    and our certified Clients. We are committed to follow
                    international accreditation standard ISO 17021-1:2015 and
                    relevant IAF regulatory requirements regarding our scope of
                    certification. To inspire trust we would ensure full
                    transparency, and would maintain complete impartiality in
                    our assessment and certification process. To promote quality
                    and impartiality,we engage only senior subject experts in
                    accordance with the applicable regulations of the ISO and
                    International Accreditation Forum (IAF). We strive to
                    continually improve our performance by establishing service
                    standards and performance indicators. We achieve our
                    objectives by establishing and following Quality management
                    protocol and by elevating the quality of our staff by
                    providing to them required knowledge, skill and procedural
                    trainings.
                    <br />
                    Director
                </p> */}
                <h3 className="title">
                    <Link to={process.env.PUBLIC_URL + `/policy/${data.id}`}>
                        {data.title}
                    </Link>
                </h3>
            </div>
        </div>
    );
};

BlogGrid.propTypes = {
    data: PropTypes.object,
    classOption: PropTypes.string,
};

BlogGrid.defaultProps = {
    classOption: "thumb",
};

export default BlogGrid;
