import React from "react";
import PropTypes from "prop-types";
import BlogGrid from "../../../components/blog";

const QualityContainer = ({ data }) => {
    return (
        <div className="blog-section section-py">
            <div className="container">
                <div className="row mb-n7">
                    <p className="align-items-center mt-10">
                        We at AMSPL, are committed to provide management system
                        conformity assessment & certification services to our
                        clients in a manner that would elevate the prestige of
                        AMSPL and our certified Clients. We are committed to
                        follow international accreditation standard ISO
                        17021-1:2015 and relevant IAF regulatory requirements
                        regarding our scope of certification. To inspire trust
                        we would ensure full transparency, and would maintain
                        complete impartiality in our assessment and
                        certification process. To promote quality and
                        impartiality, we engage only senior subject experts in
                        accordance with the applicable regulations of the ISO
                        and International Accreditation Forum (IAF). We strive
                        to continually improve our performance by establishing
                        service standards and performance indicators. We achieve
                        our objectives by establishing and following Quality
                        management protocol and by elevating the quality of our
                        staff by providing to them required knowledge, skill and
                        procedural trainings.
                    </p>
                    <p className="flex-end"> Director</p>
                </div>
            </div>
        </div>
    );
};

QualityContainer.propTypes = {
    data: PropTypes.array,
};

export default QualityContainer;
