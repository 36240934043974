import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";

const ContactForm = () => {
    const { register, errors, handleSubmit, reset } = useForm({
        mode: "onBlur",
    });
    const [isSubmitted, setIsSubmitted] = useState(false); // State to track form submission status
    const onSubmit = async (data) => {
        try {
            const response = await fetch(
                "https://aaranayacertification.com/api/webquery/",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                }
            );

            if (response.ok) {
                // Handle success
                setIsSubmitted(true); // Set isSubmitted to true when form is submitted
                alert("Form data sent successfully!");
                // Reset the form after successful submission
                reset();
                // Set isSubmitted back to false after 1 millisecond
                setTimeout(() => setIsSubmitted(false), 1);
            } else {
                // Handle errors
                console.error("Failed to send form data");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <Fragment>
            <form
                id="contactForm"
                className="row"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="col-12 col-sm-6 mb-7">
                    <input
                        type="text"
                        className="form-control"
                        id="contact_name"
                        name="contact_name"
                        placeholder="Your Name*"
                        ref={register({ required: "Name is required" })}
                    />
                    {errors.contact_name && (
                        <p>{errors.contact_name.message}</p>
                    )}
                </div>
                <div className="col-12 col-sm-6 mb-7">
                    <input
                        type="text"
                        className="form-control"
                        id="email_id"
                        name="email_id"
                        placeholder="Your Email*"
                        ref={register({
                            required: "Email is required",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "invalid email address",
                            },
                        })}
                    />
                    {errors.email_id && <p>{errors.email_id.message}</p>}
                </div>
                <div className="col-12 col-sm-6 mb-7">
                    <input
                        type="text"
                        className="form-control"
                        id="contact_no"
                        name="contact_no"
                        placeholder="Contact Number*"
                        ref={register({ required: "Number is required" })}
                    />
                    {errors.contact_no && <p>{errors.contact_no.message}</p>}
                </div>
                <div className="col-12 col-sm-6 mb-7">
                    <input
                        type="text"
                        className="form-control"
                        id="company_name"
                        name="company_name"
                        placeholder="Company Name*"
                        ref={register({ required: "CompanyName is required" })}
                    />
                    {errors.company_name && (
                        <p>{errors.company_name.message}</p>
                    )}
                </div>

                <div className="col-12 mb-9">
                    <textarea
                        className="form-control massage-control"
                        name="message"
                        id="message"
                        cols="30"
                        rows="10"
                        placeholder="Message"
                        ref={register({
                            required: "Message is required",
                        })}
                    ></textarea>
                    {errors.message && <p>{errors.message.message}</p>}
                </div>
                <div className="col-12">
                    <button
                        id="contactSubmit"
                        type="submit"
                        className="btn btn-dark btn-hover-dark"
                        data-complete-text="Well Done!"
                    >
                        Send Message
                    </button>
                    {/* Show message on successful form submission */}
                    {isSubmitted && (
                        <p className="mt-2 text-success">
                            Form submitted successfully!
                        </p>
                    )}
                </div>
            </form>
            {/* Show message when form is submitted */}
            {/* {isSubmitted && (
                <div className="alert alert-success mt-3" role="alert">
                    Form submitted successfully!
                </div>
            )} */}
        </Fragment>
    );
};

export default ContactForm;
