import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import BrandContainer from "../containers/global/brand/index";
import FunFactContainer from "../containers/global/funfact";
import TestimonialContainer from "../containers/global/testimonial";
import HomeAboutContainer from "../containers/home/about";
import HomeBlog from "../containers/home/blog";
import IntroContainer from "../containers/home/intro";
import ServiceListContainer from "../containers/service/service-list";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import Button from "../components/button";

const HomePage = () => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Aranaya" />
                <div className="wrapper">
                    <Header />
                    <IntroContainer />
                    {/* <IconBoxContainer classOption="section-pb" /> */}
                    <HomeAboutContainer />
                    <ServiceListContainer />
                    <BrandContainer />
                    <FunFactContainer classOption="mt-10 mt-lg-0" />
                    <TestimonialContainer />
                    <div className="row align-items-center">
                        <Button
                            classOption="btn btn-lg animated delay2  
                    btn-hover-secondary w-70 mb-3 mb-sm-0 bold "
                            text="Any Query?"
                            path="/contact"
                        />
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default HomePage;
