import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";

const CertificateForm = () => {
    const { register, errors, handleSubmit } = useForm({
        mode: "onBlur",
    });
    const [apiResponse, setApiResponse] = useState(null);
    // const [scope, setScope] = useState([active, suspended])

    const onSubmit = async (data) => {
        try {
            const response = await fetch(
                "https://aaranayacertification.com/api/search/",
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(data),
                }
            );
            if (response.ok) {
                // Handle success
                const responseData = await response.json();
                console.log("Response from API:", responseData);
                setApiResponse(responseData); // Update state with API response
                // toast.success("Form submitted successfully");
                // Reset the form after successful submission if needed
                // reset(); // Assuming you have reset function from react-hook-form
            } else {
                // Handle errors
                console.error("Failed to send form data");
                // toast.error("Failed to submit form");
            }
        } catch (error) {
            console.error("Error:", error);
        }
        console.log(apiResponse);
    };
    // Function to render status icon based on status
    const renderStatusIcon = (status) => {
        switch (status.toLowerCase()) {
            case "active":
                return "✔️"; // Unicode character for check mark
            case "suspended":
                return "❌"; // Unicode character for cross mark
            case "withdrawn":
                return "🔶"; // Unicode character for diamond
            default:
                return "↻"; // Unicode character for clockwise circle arrow
        }
    };
    return (
        <Fragment>
            <form
                id="certificateForm"
                className="row"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="d-flex justify-content-around">
                    <div className="col-12 col-sm-6 mb-7">
                        <input
                            type="text"
                            className="form-control col-12"
                            id="company_name"
                            name="company_name"
                            placeholder="Company Name*"
                            ref={register()}
                        />
                        {errors.company_name && (
                            <p>{errors.company_name.message}</p>
                        )}
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div className="col-12 col-sm-6 mb-7">
                        <input
                            type="text"
                            className="form-control col-12"
                            id="certificate_no"
                            name="certificate_no"
                            placeholder="Certificate Number*"
                            ref={register()}
                        />
                        {errors.certificate_no && (
                            <p>{errors.certificate_no.message}</p>
                        )}
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div className="col-12 col-sm-6 mb-7">
                        <button
                            id="submitForm"
                            name="submitForm"
                            type="submit"
                            className="btn btn-dark btn-hover-dark"
                            data-complete-text="Well Done!"
                        >
                            Search
                        </button>
                    </div>
                </div>
            </form>
            {apiResponse &&
                apiResponse.map((item, index) => (
                    <div key={index}>
                        <hr />
                        <div>
                            <span>
                                {" "}
                                <strong>
                                    Company Name: {item.company_name}
                                </strong>
                            </span>
                            &nbsp;
                            <span>{renderStatusIcon(item.status)}</span>
                        </div>
                        <p>Standards: {item.standards}</p>
                        <p>Scope: {item.scope}</p>
                        <p>Status: {item.status}</p>
                    </div>
                ))}
        </Fragment>
    );
};

export default CertificateForm;
