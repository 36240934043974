import React from "react";

const BemcsPageContainer = () => {
    return (
        <div className="fw-semi-bold mb-6 ms-10 mt-10 text-center">
            <p>
                The Biomedical Equipment Maintenance Certification Scheme (BEMC)
                is a collaborative initiative of AMTZ, the Association of Indian
                Medical Device Industry (AiMeD), and the Association of
                Healthcare Providers India (AHPI). This voluntary scheme is
                designed to ensure the effective and safe maintenance of medical
                equipment and systems used in healthcare settings. It
                encompasses testing and verification processes for medical
                equipment or combinations of equipment that require periodic
                maintenance.
            </p>
            <p>
                BEMC can be implemented by hospitals, manufacturers biomedical
                maintenance departments, and third-party maintenance providers.
                Notably, it excludes implantable devices, single-use items, and
                equipment not requiring preventive maintenance. It adheres to
                international standards like ISO/IEC 17065 and aligns with
                established best practices.
            </p>
            <p>
                The scheme addresses a global need for consistent biomedical
                equipment maintenance standards, particularly in countries with
                limited technical resources. It aims to enhance healthcare
                delivery by ensuring equipment reliability. The scheme evaluates
                maintenance efficacy post-installation, servicing, and repair,
                aligning with regulatory and manufacturer requirements. Service
                providers, including AMC, CMC, hospitals, clinics, and OEMs, are
                responsible for ongoing compliance. BEMC plays a crucial role in
                maintaining quality, performance, and safety in healthcare
                facilities.
            </p>
            <p className="text-center">
                For detailed information about the scheme please reach out to us
                <br />
                <a href="mailto:info@example.com">
                    info@aaranayacertification.com
                </a>
            </p>
        </div>
    );
};

export default BemcsPageContainer;
