import { useEffect, useRef } from "react";
import Button from "../../components/button";
import SectionTitle from "../../components/section-title";
import Parallax from "parallax-js";

const AboutContainer = () => {
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        });

        parallaxInstance.enable();

        return () => parallaxInstance.disable();
    }, []);
    return (
        <div className="about-us position-relative">
            <div className="container">
                <p className="align-items-center mt-10">
                    We are an autonomous body providing Independednt Assessment
                    and Certification and other related services globally. Our
                    main objective is to operate as a body that dedicatedly
                    provide client a conclusive & concrete advice and
                    recommendation to their needs, to make their business system
                    work properly. We also make sure that client sanity, privacy
                    or any kind of operation much not get distrupt because of
                    any reason. We make ourselves totally accountable for client
                    sanity, privacy or any kind of operation much not get
                    disrupt because of any reason. We make ourselves totally
                    accountable for client each & every query or question
                    related to our domain. Our main focus always lay towards the
                    safety and security of our client, and we firmly believe
                    that our trustworthiness and credibility totally depends on
                    our legitimate and sincere attitude towards them. We provide
                    certificatio, auditing, inspection and training in numerous
                    field and perpetually try to create a generous and
                    legitimate relation with client in a cost efficient way.
                </p>
                <div className="row mb-n7 align-items-center">
                    <div className="col-xl-5 col-md-6 offset-xl-1 mb-7">
                        <div className="about-content">
                            <SectionTitle
                                classOption="title-section"
                                subTitle="ABOUT US"
                                title=""
                            />
                            <span className="date-meta">
                                Since 2018 <span className="hr"></span>
                            </span>
                            <p className="mb-5">
                                The endeavourous journey of AMSPL was started in
                                2018 in India, We are one of the most dedicated
                                and enthusiastic certification, auditing,
                                inspection and training organizations. We are
                                totally concerned about the client’s issues;
                                henceforth we work with a very marvelous team of
                                well trained and qualified people, who have
                                ample amount of experience in their respective
                                fields. Our work area comprises not only Indian
                                subcontinent,but other countries too.
                            </p>
                            <p className="mb-8">
                                Our key axiom is to provide customers solutions
                                based on their satisfaction, the required safety
                                measures,looking the quality,social, in a
                                sustainable as well as cost efficient way. We
                                always look after the importance of transparency
                                and impartiality in our work procedure. We have
                                a proper system that works as watchdog to avoid
                                the conflict of interest and ensures the
                                perfection in all our assignments which include
                                management system certification,inspection,
                                auditing, training and personal certification
                                and products. Our modest and humble
                                professionals always stand with the clients to
                                help and serve them regarding any query. We
                                believe in developing a faithful and unbiased
                                relationship.
                            </p>
                            {/* <Button
                                classOption="btn btn-lg btn-dark btn-hover-dark"
                                text="Learn more"
                                path="/about"
                            /> */}
                        </div>
                    </div>
                    <div className="col-xl-6 order-lg-first col-md-6 mb-7">
                        <div
                            className="about-photo scene text-center text-lg-left"
                            id="scene"
                            ref={sceneEl}
                        >
                            <div data-depth="0.2">
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/about/2.png`}
                                    alt="about"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutContainer;
