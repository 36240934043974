import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";

const PolicyPage = () => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Aranaya – Policy" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title="Policy"
                        excerpt=""
                        image="./images/service/8.png"
                    />
                    <div className="about-us position-relative">
                        <div className="container">
                            <p className="align-items-center mt-10">
                                Our certification process is highly transparent
                                and unbiased. Since the reputation of AMSPL and
                                our client must improve, we think that quality
                                can be improved through conformity evaluation.
                                We have a strong commitment to upholding the
                                applicable IAF requirements as well as the
                                global accreditation standard. We work
                                impartially by gathering objective evidence and
                                making decisions based on them.
                            </p>
                        </div>
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default PolicyPage;
