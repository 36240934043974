import React from "react";

const ApplyContainer = () => {
    return (
        <section className="contact-section section-py">
            <div className="container d-flex justify-content-center">
                <div className="row mb-n7">
                    <h5>Download</h5>
                    <br />
                    <br />
                    <ol type="1">
                        <li>
                            <a href="./media/AMSPL-F-01.docx">
                                Application Form
                            </a>
                        </li>
                        <li>
                            <a href="./media/AMSPL-F-04.docx">Contract Form </a>
                        </li>
                    </ol>
                </div>
            </div>
        </section>
    );
};

export default ApplyContainer;
