import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";

const PolicyApplicationPage = () => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Aranaya – Policy" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title="Contract"
                        excerpt=""
                        image="./images/service/8.png"
                    />
                    <div className="about-us position-relative">
                        <div className="container">
                            <br />
                            <p className="text-center mt-15">Coming soon !!!</p>
                        </div>
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default PolicyApplicationPage;
