import React from "react";
import PropTypes from "prop-types";

const ImpartialityContainer = () => {
    return (
        <div className="blog-section section-py">
            <div className="container">
                <div className="row mb-n7">
                    <p className="align-items-center mt-10">
                        Being impartial is important, but being perceived to be
                        impartial is as much important. This perception of
                        impartiality is established by gathering objective
                        evidence and making decision on the basis of these
                        objective evidences. Impartiality is also ensured by
                        disallowing involvement of any auditor or technical
                        committee member in the process of audit and
                        certification of any client with whom the officer has
                        close relationship, familiarity or business
                        interactions.
                    </p>
                    <h4>STATEMENT OF IMPARTIALITY</h4>
                    <p>
                        Aaranaya Management Services Pvt. Ltd. understands the
                        importance of avoiding conflict of interest and ensuring
                        complete impartiality in the auditing and certification
                        process. At AMSPL we ensure Impartiality by -
                    </p>
                    <ul>
                        <li>
                            Analysing, identifying and documenting possible
                            situations of partiality and conflict of interest,
                            and by taking appropriate action to eliminate or
                            neutralize these possibilities.
                        </li>
                        <li>
                            Educating certification personnel about avoiding
                            possible issues that might affect impartiality.
                        </li>
                        <li>
                            Legally binding certification personnel to disclose
                            relevant information regarding issues that may lead
                            to possibilities of conflict of interest.
                        </li>
                        <li>
                            Ensuring objectivity of the certification process by
                            using only competent personnel.
                        </li>
                        <li>
                            Establishing an independent Committee for
                            safeguarding impartiality (Impartiality committee)
                            to monitor and enforce impartiality in audit and
                            certification process.
                        </li>
                    </ul>
                    <br />
                    <p className="m-0">Director</p>
                    <br />
                    <br />
                    <p>
                        Our mechanism for ensuring Impartiality To look after
                        matters related to Credibility and Impartiality of the
                        Auditing and Certification process, we have established
                        a “Committee for Safeguarding Impartiality”. To ensure
                        impartiality in the structure and functioning of the
                        committee, all members are selected from outside the
                        Certification Body. These professionals represent
                        different stakeholders, who have interest in impartial
                        audit and certification process. Infringement in audit
                        and certification process, if any, is noted and
                        appropriate corrective and preventive action is taken.
                        If the CB does not take satisfactory action the
                        Impartiality committee is authorized to raise the issue
                        before regulatory authorities. The DIRECTOR sits in the
                        committee meetings as a non- voting member to provide
                        required information and to implement the decisions
                        taken by the Impartiality Committee. The committee can
                        be contacted at info@aaranayacertification.com
                    </p>
                </div>
            </div>
        </div>
    );
};

ImpartialityContainer.propTypes = {
    data: PropTypes.array,
};

export default ImpartialityContainer;
