import React from "react";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";

const AppealPage = () => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Aranaya – Appeal" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title="Appeal"
                        image="./images/contact/certificate.png"
                    />
                    <div className="about-us position-relative">
                        <div className="container">
                            <p className="align-items-center mt-10">
                                Our certification process is totally impartial
                                and is regulated by ISO standards and IAF
                                guidelines. To ensure transparency and
                                credibility of our audit & certification
                                decisions, we accept and act upon APPEALs
                                submitted by our Clients and stakeholders
                                against our audit or certification decision.
                                Appeal should be addressed to the CEO / MD, and
                                it should be submitted in writing, personally,
                                by courier or by Email. Every appeal is looked
                                into directly by the C E O and it is
                                acknowledged within 3 working days. Result of
                                action taken is communicated to the appellant
                                within 10 days. If the client is not satisfied
                                by the decision regarding his or her appeal,
                                final appeal may be lodged with our Impartiality
                                Committee Chairman. The decision of the
                                Impartiality committee is communicated to the
                                appellant within 30 working days of such appeal,
                                and the decision of the Impartiality committee
                                is deemed final.
                            </p>
                        </div>
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default AppealPage;
