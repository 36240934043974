import React from "react";
import CertificateForm from "../../components/certificate-form";
// import CertificateInfo from "../../components/certificate-info";

const CertificateContainer = () => {
    return (
        <section className="contact-section section-py">
            <div className="container">
                <div className="row mb-n7">
                    <div className="col-xl-6 col-lg-6 mb-7">
                        <div className="contact-title-section">
                            <h3 className="title">Your Certificate</h3>
                        </div>
                        <CertificateForm />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CertificateContainer;
