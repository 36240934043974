import React from "react";

const UafPageContainer = () => {
    return (
        <div className="fw-semi-bold mb-6 ms-10 mt-10 text-center">
            <p>
                AMSPL has been accriditated by UAF USA (UAF is a not-for-profit
                organization. UAF is internationally recognized by having a
                signatory status across multilateral recognition arrangement of
                International Accreditation Forum (IAF) and mutual recognition
                arrangements of Asia Pacific Accreditation Cooperation (APAC).)
                for Management System Certification ISO 9001:2015 and ISO
                14001:2015.
            </p>
        </div>
    );
};

export default UafPageContainer;
